/* global COUNTRY_CODE */
/* global TruTranslations */
/* global return_link */

let postal_to_provinces = {
    'A': ['NL'],
    'B': ['NS'],
    'C': ['PE'],
    'E': ['NB'],
    'G': ['QC'],
    'H': ['QC'],
    'J': ['QC'],
    'K': ['ON'],
    'L': ['ON'],
    'M': ['ON'],
    'N': ['ON'],
    'P': ['ON'],
    'R': ['MB'],
    'S': ['SK'],
    'T': ['AB'],
    'V': ['BC'],
    'X': ['NT', 'NU'],
    'Y': ['YT'],
}

let zone_to_provinces = {
    'ON': ['ON'],
    'AB': ['AB'],
    'BC': ['BC'],
    'QC': ['QC'],
    'NATION': ['MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'PE', 'QC', 'SK', 'YT'],
}

const CheckoutValidation = {
    checkWelcomePostal(){
        let postal_field = this.getPostalField();
        let search_field = $( "input[name='search']" );

        if (this.isValidPostalCode(postal_field.val())) {
            $("#form-error").hide();
            postal_field.removeClass("input-error");
            search_field.removeClass("input-error");
            return true;
        } else {
            $("#form-error").show();
            postal_field.addClass("input-error");
            search_field.addClass("input-error");
            $( "#form__submit" ).removeClass('loading').attr('disabled', false);
            return false;
        }
    },

    getPostalField() {
        let postal_field = $("input[name='postal_code']");
        if (!postal_field.length) {
            // Use the non-autocomplete field
            postal_field = $("input[name='postal_code--no-auto']");
        }
        return postal_field;
    },

    getPostalProvinceError(postalCode, prov, zones) {
      // postal code is not in user's provided province
      if (!postal_to_provinces[postalCode[0].toUpperCase()].includes(prov))
        return TruTranslations['postal_code_province_mismatch'];

      // see if product's zones service the province
      for (let z of zones)
        if (zone_to_provinces[z].includes(prov))
          return '';  // success, no error message

      // postal code is not in the product's zone
      if (typeof return_link !== 'string')
        return TruTranslations['checkout_product_not_in_area'];

      // provide user with a link for their region
      let urlString = return_link + "?region="
      if (!['AB', 'BC', 'ON'].includes(prov)) prov = 'NATION';
      urlString += prov;
      return TruTranslations['curated_box_not_available_in_your_region'].replace('<url>', urlString);
    },

    validatePostalCodeAndProvince(zones, valid, focus_on) {
        let postal_field = this.getPostalField();
        let province_field = $("#province");

        // if both fields don't exist, likely a gift card, return valid
        if (postal_field.length === 0 && province_field.length === 0) return valid;

        // validate postal code field
        let postalCode = postal_field.val().trim().toUpperCase();
        let postalValid = COUNTRY_CODE === 'US' ? this.isValidUSZip(postalCode) : this.isValidPostalCode(postalCode);
        valid = this.validateField(postal_field, valid, focus_on, postalValid);

        // validate province field
        let prov = province_field.val();
        let provValid = prov.length === 2;
        valid = this.validateField(province_field, valid, focus_on, provValid)

        if (!postalValid || !provValid) return valid;  // if either postal or province are invalid -> don't compare them
        if (COUNTRY_CODE === 'US') return valid;  // 'US' needs no further validation;

        // compare province to postal code
        let errMsg = this.getPostalProvinceError(postalCode, prov, zones);
        if (!errMsg) return valid;

        // mark postal code and province fields as having an error
        postal_field.addClass('error');
        province_field.addClass('error');

        // populate and show errorField with message
        let errorField = $('#checkout-errors');
        errorField.html(`<span class="messages">${errMsg}</span>`);
        errorField.show();

        if (valid) this.fieldFocus(errorField);
        return false;
    },

    fieldFocus(fieldToFocus) {
        try {
            $('html, body').animate({
                scrollTop: fieldToFocus.offset().top
            }, 750);
        } catch (e) { /* do nothing */ }
    },

    isValidUSZip(userInput) {
        return /^\d{5}(-\d{4})?$/.test(userInput);
    },

    isValidPostalCode(postalCode) {
        return /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ](-| |)[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/
            .test(postalCode.replace(" ", "").toUpperCase());
    },

    isValidEmail(email) {
        // email address can be stored in our DB
        if (email.length > 255) return false;

        // username exists
        let posAt = email.indexOf('@');
        if (posAt <= 0) return false;

        // domain length
        let domain = email.substring(posAt + 1);
        if (domain.length > 67) return false;

        // domain regex format
        return domain.match(/^([a-z0-9][a-z0-9-]{0,63}\.){1,6}[a-z]{2,64}$/gi);
    },

    toggleFields() {
        let building_type_field = $("#building_type");
        let apartment_disclaimer = $('#apartmentDisclaimer');
        let confirm_container = $('#building_type_confirm_container');
        let disclaimer_checkbox = $('#disclaimer-checkbox');

        // Validate Building Type
        apartment_disclaimer.hide();
        confirm_container.hide();

        // if there's no building_type selected (or existing) then no disclaimer checkbox needed
        if (!building_type_field.val()) return;

        // confirmation of disclaimer needed
        confirm_container.show();
        disclaimer_checkbox.prop("checked", false);
        if (building_type_field.val() === 'Apartment') {
            // apartments get an extra disclaimer
            apartment_disclaimer.show();
        }
    },

    validateStep2(zones) {
        let valid = true;

        valid = this.validateField($('#employee_code'), valid);
        valid = this.validateShippingDate($('#giftShippingDate'), valid);
        valid = this.validateField($('#purchaserFirstName'), valid);
        valid = this.validateField($('#purchaserLastName'), valid);
        valid = this.validateField($('#first_name'), valid);
        valid = this.validateField($('#last_name'), valid);
        valid = this.validatePhone($('#phone'), valid);
        valid = this.validateEmailField($('#recipientEmail'), valid);
        valid = this.validateAddressFields(zones, valid);
        valid = this.validateGiftCardAmount($('#giftCardAmount'), valid);
        valid = this.validateCheckbox($('#disclaimer-checkbox'), valid, $('#disclaimerSection'));

        return valid;
    },

    validateField(field, valid, focus_on=null, isValid=null) {
      if (field.length === 0) return valid;

      if (isValid || (isValid === null && field.val() !== "")) {
        field.removeClass('error');
        return valid
      }

      field.addClass('error');
      if (valid) this.fieldFocus(focus_on ? focus_on : field.parent());
      return false
    },

    validateEmailField(field, valid, focus_on=null) {
      return this.validateField(field, valid, focus_on, field.length && this.isValidEmail(field.val()));
    },

    validatePasswordField(field, valid, focus_on=null) {
      const passwordLength = field.val().length;
      return this.validateField(field, valid, focus_on, passwordLength >= 6 && passwordLength <= 72);
    },

    validateShippingDate(field, valid, focus_on=null) {
      if (field.length === 0) return valid;
      return this.validateField(field, valid, focus_on, field[0].selectedIndex !== 0);
    },

    validatePhone(field, valid, focus_on=null) {
      // Ensure the phone number is exactly 10 digits excluding special characters added by the mask.
      if (field.length === 0) return valid;
      let n = field.val().replace(/[()\- ]/g, '');
      let isValid = n.match(/[0-9]{10}/g) !== null;
      return this.validateField(field, valid, focus_on, isValid)
    },

    validatePostalField(field, valid, focus_on=null) {
      let postalCode = field.val().trim().toUpperCase();
      let postalValid = COUNTRY_CODE === 'US' ? this.isValidUSZip(postalCode) : this.isValidPostalCode(postalCode);
      return this.validateField(field, valid, focus_on, postalValid);
    },

    validateAddressFields(zones, valid) {
      // all address field errors should focus on the top of autocompleteFields;
      // however, if in manual mode, focus on the top of the manualEntryFields
      let acf = $('#autocompleteFields').is(':visible') ? $('#autocompleteFields') : $('#manualEntryFields');
      valid = this.validateField($('#street-address'), valid, acf);
      valid = this.validateField($('#city'), valid, acf);
      valid = this.validatePostalCodeAndProvince(zones, valid, acf);
      valid = this.validateField($("#building_type"), valid, acf);
      return valid;
    },

    validateCheckbox(field, valid, focus_on=null) {
      if (field.length === 0) return valid;
      return this.validateField(field, valid, focus_on, field[0].checked);
    },

    validateGiftCardAmount(field, valid) {
      // get the gift card amount
      let gc_amount = $('#giftCardAmount').val()

      // skip validation if there is no gift card amount (i.e. not a gift card purchase)
      if (!(gc_amount)) return valid;
      if (gc_amount == 'custom') gc_amount = $('#customGiftCardAmount').val();

      // validate amount
      let errorField = $('#checkout-errors');
      let isValid = /^\d{2,5}(.\d{1,2})?$/.test(gc_amount) && gc_amount >= 25;
      if (!isValid) {
        errorField.html(`<p>${TruTranslations['gift_card_amount_invalid']}</p>`);
        errorField.show();
      }
      return this.validateField(field, valid, errorField, isValid)
    },

    postalZone(postal){
      if (postal.match(/^\d/)) return 'IL';
      switch (postal.substring(0,1).toLowerCase()) {
      case 'a':
      case 'b':
      case 'c':
      case 'e':
      case 'g':
      case 'h':
      case 'j':
        return 'NATION';
      case 'k':
      case 'l':
      case 'm':
      case 'n':
      case 'p':
        return 'ON';
      case 't':
        return 'AB';
      case 'v':
        return 'BC';
      }
      return '';
    },
};

export default CheckoutValidation;
